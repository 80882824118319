import { render, staticRenderFns } from "./RolPage.vue?vue&type=template&id=bc060046&scoped=true"
import script from "./RolPage.js?vue&type=script&lang=js&external"
export * from "./RolPage.js?vue&type=script&lang=js&external"
import style0 from "./RolPage.vue?vue&type=style&index=0&id=bc060046&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc060046",
  null
  
)

export default component.exports