import { render, staticRenderFns } from "./ProduccionPage.vue?vue&type=template&id=61f5f4ac&scoped=true"
import script from "./ProduccionPage.js?vue&type=script&lang=js&external"
export * from "./ProduccionPage.js?vue&type=script&lang=js&external"
import style0 from "./ProduccionPage.vue?vue&type=style&index=0&id=61f5f4ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f5f4ac",
  null
  
)

export default component.exports