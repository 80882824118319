import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "SubCategoriaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "SubCategoriaPage",
            ss: ss,
            ajax: {
                "url": ss.indexSubCategoria(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'SubCategoria', name: 'sub.SubCategoria', title: 'Medida' },
                // { data: 'Categoria', name: 'Categoria', title: 'Categoria' },
                // { data: 'Foto', name: 'Foto', title: 'Foto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            subCategorias: [],
            // categorias: [],
            subCategoria: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    methods: {
        // getCategoria() {
        //     this.ss.listCategoria().then(
        //         (result) => {
        //             let response = result.data;
        //             this.categorias = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },
        newSubCategoria() {
            this.subCategoria = {};
            this.$refs['frm-subCategoria'].show();
        },
        showSubCategoria(id) {
            this.isLoading=true;
            this.ss.showSubCategoria(id).then(
                (result) => {
                    let response = result.data;
                    this.subCategoria = response.data;
                    this.$refs['view-subCategoria'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editSubCategoria() {
            this.$refs['frm-subCategoria'].show();
            this.$refs['view-subCategoria'].hide();
        },
        cancelSubCategoria() {
            if (this.subCategoria.id) {
                this.$refs['view-subCategoria'].show();
            }
            this.$refs['frm-subCategoria'].hide();
        },
        saveSubCategoria() {
            this.ss.storeSubCategoria(this.subCategoria).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-subCategoria'].hide();
                    this.$refs['datatable-subCategoria'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteSubCategoria() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroySubCategoria(this.subCategoria)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-subCategoria'].hide();
                                this.$refs['datatable-subCategoria'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        draw() {
            window.$('body').on('click', '.btn-datatable-SubCategoria', (evt) => {                
                const data = window.$(evt.target)[0].id;
                this.showSubCategoria(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            // this.getCategoria();
        }
    }
};
