import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ClientePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ClientePage",
            ss: ss,
            ajax: {
                "url": ss.indexCliente(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                // { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'Nro.', orderable: false, searchable: false },
                { data: 'Persona', name: 'cli.Persona', title: 'Cliente' },
                // { data: 'Departamento', name: 'dep.Departamento', title: 'Departamento' },
                // { data: 'Mercado', name: 'me.Mercado', title: 'Mercado' },
                // { data: 'Foto', name: 'Foto', title: 'Foto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            departamentos: [],
            mercadoSegunCiudads: [],
            mercados: [],
            clientes: [],
            cliente: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    methods: {
        getMercadoSegunCiudad() {
            this.ss.listMercadoSegunCiudad(this.cliente.Departamento).then(
                (result) => {
                    let response = result.data;
                    this.mercadoSegunCiudads = response.data;

                    console.log(this.mercadoSegunCiudads);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDepartamento() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.departamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getMercado() {
            this.ss.listMercado().then(
                (result) => {
                    let response = result.data;
                    this.mercados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newCliente() {
            this.cliente = {};
            this.$refs['frm-cliente'].show();
        },
        showCliente(id) {
            
            this.isLoading=true;
            this.ss.showCliente(id).then(
                (result) => {
                    let response = result.data;
                    this.cliente = response.data;
                    this.$refs['view-cliente'].show();
                    this.isLoading=false;

                    this.cliente.Departamento = this.cliente.mercado.Departamento;
                    // this.cliente.Mercado = this.cliente.Mercado;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editCliente() {
            this.getMercadoSegunCiudad()
            this.$refs['frm-cliente'].show();
            this.$refs['view-cliente'].hide();
        },
        cancelCliente() {
            if (this.cliente.id) {
                this.$refs['view-cliente'].show();
            }
            this.$refs['frm-cliente'].hide();
        },
        saveCliente() {
            this.ss.storeCliente(this.cliente).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-cliente'].hide();
                    this.$refs['datatable-cliente'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        // deleteCliente() {
        //     this.$swal({
        //         title: "Estas seguro que deseas eliminar?",
        //         text: "Esta accion es irreversible!",
        //         icon: "warning",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        //         .then((willDelete) => {
        //             if (willDelete) {
        //                 this.ss.destroyCliente(this.cliente)
        //                     .then((result) => {
        //                         let response = result.data;
        //                         console.log(response);
        //                         this.$bvToast.toast(
        //                             response.msg,
        //                             {
        //                                 title: 'Correcto',
        //                                 variant: 'success',
        //                                 autoHideDelay: 5000
        //                             }
        //                         )
        //                         this.$refs['view-cliente'].hide();
        //                         this.$refs['datatable-cliente'].reload();
        //                     })
        //                     .catch(error => {
        //                         console.log(error);
        //                     })
        //             } else {
        //                 //swal("Your imaginary file is safe!");
        //             }
        //         });
        // },
        // funciona bien
        deleteCliente() {
            this.$bvModal.msgBoxConfirm('Estas seguro que deseas eliminar?', {
                title: 'Confirmación',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'SI',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.ss.destroyCliente(this.cliente)
                        .then(result => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-cliente'].hide();
                            this.$refs['datatable-cliente'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
            .catch(err => {
                // Manejar el error
                console.log(err);
            });
        },

        // tiene un mejor diseño
        // deleteCliente() {
        //     this.$swal({
        //         title: "Estas seguro que deseas eliminar?",
        //         text: "Esta acción es irreversible!",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonText: "YES",
        //         cancelButtonText: "NO",
        //         customClass: {
        //             confirmButton: 'btn btn-danger',
        //             cancelButton: 'btn btn-secondary'
        //         },
        //         buttonsStyling: false
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.ss.destroyCliente(this.cliente)
        //                 .then((result) => {
        //                     let response = result.data;
        //                     console.log(response);
        //                     this.$bvToast.toast(
        //                         response.msg,
        //                         {
        //                             title: 'Correcto',
        //                             variant: 'success',
        //                             autoHideDelay: 5000
        //                         }
        //                     );
        //                     this.$refs['view-cliente'].hide();
        //                     this.$refs['datatable-cliente'].reload();
        //                 })
        //                 .catch(error => {
        //                     console.log(error);
        //                 });
        //         }
        //     });
        // },

        draw() {
            window.$('body').on('click', '.btn-datatable-Cliente', (evt) => {                
                const data = window.$(evt.target)[0].id;
                this.showCliente(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.getDepartamento();
            this.getMercado();
        }
    }
};
