import { render, staticRenderFns } from "./PersonaPage.vue?vue&type=template&id=b5fea670&scoped=true"
import script from "./PersonaPage.js?vue&type=script&lang=js&external"
export * from "./PersonaPage.js?vue&type=script&lang=js&external"
import style0 from "./PersonaPage.vue?vue&type=style&index=0&id=b5fea670&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5fea670",
  null
  
)

export default component.exports