var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[(this.$router.currentRoute.name !== 'Login')?_c('v-app-bar',{style:({
                backgroundImage: _vm.scrolled ? 'url(https://rosweb.rosabiweb.com/images/loading1.png)' : 'none',
                backgroundColor: _vm.scrolled ? '#ac0f0f' : 'transparent',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                transition: 'background-image 0.3s ease-in-out, background-color 0.5s ease-in-out'
            }),attrs:{"app":"","dark":"","flat":""}},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){$event.preventDefault();return _vm.cerrarSesion.apply(null, arguments)}}},[_vm._v("mdi-logout")])],1)],1):_vm._e(),(this.$router.currentRoute.name !== 'Login')?_c('v-navigation-drawer',{staticStyle:{"background":"url(https://rosweb.rosabiweb.com/images/img8.png) center / cover"},attrs:{"color":"white","permanent":_vm.$vuetify.breakpoint.mdAndUp,"app":"","flat":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('br'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticStyle:{"height":"90px","width":"90px","margin":"0 auto","border":"2px solid white"}},[_c('img',{attrs:{"src":_vm.auth.URLFoto}})])],1),_c('v-list-item-content',{staticClass:"text-center text-dark"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.auth.Persona)+" ")]),_c('v-list-item-subtitle',[_vm._v("Sesion Iniciada ")])],1)]},proxy:true}],null,false,3578667272),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-divider'),_c('MenuVuetify')],1):_vm._e(),_c('v-main',{class:{'main-background': this.$router.currentRoute.name === 'PrincipalPage'},staticStyle:{"background":"#AC0F0F"},style:(this.$router.currentRoute.name === 'PrincipalPage' ? 
                'background-image: url(https://rosweb.rosabiweb.com/images/bienvenido.jpeg); background-size: cover; background-position: center; height: 100vh; padding-left: 0px;' : '')},[_c('router-view',{attrs:{"color":"#c0392b"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }