var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    // Rol
    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    // Producto
    showProducto(id) {
        return axios.get('api/Producto/show?id=' + id);
    }

    indexProducto() {
        // return axios.defaults.baseURL + 'api/Producto/index';
        return axios.get('/api/Producto/index', {
            headers: this.getToken()
        });
    }

    listProducto() {
        return axios.get('api/Producto/list');
    }

    storeProducto(item) {
        return axios.post('api/Producto/store', item);
    }

    destroyProducto(item) {
        return axios.post('api/Producto/destroy', item);
    }
    
    // Categoria
    showCategoria(id) {
        return axios.get('api/Categoria/show?id=' + id);
    }

    indexCategoria() {
        return axios.defaults.baseURL + 'api/Categoria/index';
    }

    listCategoria() {
        return axios.get('api/Categoria/list');
    }

    storeCategoria(item) {
        return axios.post('api/Categoria/store', item);
    }

    destroyCategoria(item) {
        return axios.post('api/Categoria/destroy', item);
    }
    
    // Produccion
    showProduccion(id) {
        return axios.get('api/Produccion/show?id=' + id);
    }

    indexProduccion() {
        return axios.defaults.baseURL + 'api/Produccion/index';
    }

    listProduccion() {
        return axios.get('api/Produccion/list');
    }

    storeProduccion(item) {
        return axios.post('api/Produccion/store', item);
    }

    destroyProduccion(item) {
        return axios.post('api/Produccion/destroy', item);
    }
    
    // SubCategoria
    showSubCategoria(id) {
        return axios.get('api/SubCategoria/show?id=' + id);
    }

    indexSubCategoria() {
        return axios.defaults.baseURL + 'api/SubCategoria/index';
    }

    listSubCategoria() {
        return axios.get('api/SubCategoria/list');
    }

    storeSubCategoria(item) {
        return axios.post('api/SubCategoria/store', item);
    }

    destroySubCategoria(item) {
        return axios.post('api/SubCategoria/destroy', item);
    }
    
    // Departamento
    showDepartamento(id) {
        return axios.get('api/Departamento/show?id=' + id);
    }

    indexDepartamento() {
        return axios.defaults.baseURL + 'api/Departamento/index';
    }

    listDepartamento() {
        return axios.get('api/Departamento/list');
    }

    storeDepartamento(item) {
        return axios.post('api/Departamento/store', item);
    }

    destroyDepartamento(item) {
        return axios.post('api/Departamento/destroy', item);
    }
    
    // Mercado
    showMercado(id) {
        return axios.get('api/Mercado/show?id=' + id);
    }

    indexMercado() {
        return axios.defaults.baseURL + 'api/Mercado/index';
    }

    listMercado() {
        return axios.get('api/Mercado/list');
    }

    storeMercado(item) {
        return axios.post('api/Mercado/store', item);
    }

    destroyMercado(item) {
        return axios.post('api/Mercado/destroy', item);
    }
    
    // Cliente
    showCliente(id) {
        return axios.get('api/Cliente/show?id=' + id);
    }

    indexCliente() {
        return axios.defaults.baseURL + 'api/Cliente/index';
    }

    listCliente() {
        return axios.get('api/Cliente/list');
    }

    storeCliente(item) {
        return axios.post('api/Cliente/store', item);
    }

    destroyCliente(item) {
        return axios.post('api/Cliente/destroy', item);
    }
    
    // Venta
    showVenta(id) {
        return axios.get('api/Venta/show?id=' + id);
    }

    indexVenta() {
        return axios.get('/api/Venta/index', {
            headers: this.getToken()
        });
    }
    
    indexVentaPagada() {
        return axios.defaults.baseURL + 'api/Venta/indexVentaPagada';
    }
    
    indexVentaPendiente() {
        return axios.defaults.baseURL + 'api/Venta/indexVentaPendiente';
    }

    listVenta() {
        return axios.get('api/Venta/list');
    }

    storeVenta(item) {
        return axios.post('api/Venta/store', item);
    }

    destroyVenta(item) {
        return axios.post('api/Venta/destroy', item);
    }

    // VentaDetalle
    storeVentaDetalle(item) {
        return axios.post('api/VentaDetalle/store', item);
    }
    
    // GuardarPago
    storePago(item) {
        return axios.post('api/Pago/store', item);
    }

    // REPORTES
    GenerarVentaID(id) {
        return axios.get('api/Venta/generarVenta?id=' + id);
    }
    
    GenerarEImprimir(id) {
        return axios.get('api/Venta/generarVentaImp?id=' + id);
    }

    // Lista de Mercados por Ciudad
    listMercadoSegunCiudad(id) {
        return axios.get('api/Mercado/listMercadoSegunCiudad?Departamento=' + id);
    }
    
    // Lista de Clientes por Mercado
    listClienteSegunMercado(id) {
        return axios.get('api/Cliente/listClienteSegunMercado?Mercado=' + id);
    }

    //? REPORTES
    GenerarVentas(Gestion, Mes) {
        return axios.get('api/Venta/generarMesVentaImp', {
            params: {
                Gestion: Gestion,
                Mes: Mes
            }
        });
    }
    GenerarDeuda(Gestion, Mes) {
        return axios.get('api/Venta/generarDeudasImp', {
            params: {
                Gestion: Gestion,
                Mes: Mes
            }
        });
    }

    // listFechaCargos(id, id1, id2) {
    //     return axios.get('api/Persona/listFechaCargos', {
    //         params: {
    //             Fecha: id,
    //             Cargo: id1,
    //             Campus: id2
    //         }
    //     });
    // }
}