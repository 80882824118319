import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "VentaPendientePage",
    data() {
        let ss = new MainService();
        return {
            msg: "VentaPendientePage",
            ss: ss,
            ajax: {
                "url": ss.indexVentaPendiente(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                // { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'Nro.', orderable: false, searchable: false },
                { data: 'Persona', name: 'Nombres', title: 'Cliente' },
                // { data: 'Total', name: 'Total', title: 'Total' },
                // { data: 'montopagado', name: 'MontoPagado', title: 'Monto Pagado' },
                {
                    data: null,
                    name: 'Pendiente',
                    title: 'Pendiente',
                    render: function(data, type, row) {
                        let total = row.Total || 0;
                        let pagado = row.MontoPagado || 0;
                        let pendiente = total - pagado;
                        return pendiente.toFixed(2);
                    }
                },
                // { data: 'FechaEntrega', name: 'FechaEntrega', title: 'FechaEntrega' },
                // { data: 'EstadoPago', name: 'EstadoPago', title: 'EstadoPago' },
                // { data: 'Foto', name: 'Foto', title: 'Foto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            fields: [
                { key: 'productName', label: 'Producto' },
                { key: 'precioUnitario', label: 'P/U' },
                { key: 'cantidad', label: 'Cantidad' },
                { key: 'subTotal', label: 'SubTotal', formatter: (value, key, item) => (item.cantidad * item.precioUnitario).toFixed(2) },
                { key: 'eliminar', label: 'Borrar' },
            ],
            clientes: [],
            productos: [],
            ventaPendienteDetalles: [],
            ventaPendientes: [],
            ventaPendiente: {},
            pago: {},
            ventaPendienteDetalle: {},
            departamentos: [],
            cliente: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    methods: {
        savePago() {
            console.log('IDVenta',this.ventaPendiente.id)
            this.pago.Venta=this.ventaPendiente.id
            
            this.ss.storePago(this.pago).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    );
        
                    // Actualizar la vista o cerrar el modal
                    this.$refs['frm-nuevoPago'].hide();
                    this.$refs['view-ventaPendiente'].hide();
                    this.$refs['datatable-ventaPendiente'].reload();
                    // this.$refs['datatable-pago'].reload();
        
                    // Actualizar el estado de la venta después del pago
                    // this.getVenta(this.pago.Venta);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        calcularTotal() {
            let total = 0;
            this.ventaPendienteDetalles.forEach(item => {
                total += item.precioUnitario * item.cantidad;
            });
            this.ventaPendiente.Total = total;
        },
        saveVentaPendienteDetalle() {
            if (this.ventaPendiente.Producto && this.ventaPendiente.Cantidad > 0) {
                const existingProduct = this.ventaPendienteDetalles.find(
                    (item) => item.productId === this.ventaPendiente.Producto
                );
        
                const product = this.productos.find(
                    (p) => p.id === this.ventaPendiente.Producto
                );
        
                if (existingProduct) {
                    // Si el producto ya existe, sumar la cantidad
                    existingProduct.cantidad += Number(this.ventaPendiente.Cantidad);
                } else {
                    // Si el producto no existe, agregarlo al array ventaPendienteDetalles
                    this.ventaPendienteDetalles.push({
                        productId: this.ventaPendiente.Producto,
                        productName: product.Producto,
                        cantidad: Number(this.ventaPendiente.Cantidad),
                        precioUnitario: product.PrecioUnitario // Guardar el precio unitario del producto
                    });
                }
        
                this.ventaPendiente.Producto = '';
                this.ventaPendiente.Cantidad = '';
                this.errorBag = {};
        
                // Calcular el total de la ventaPendiente
                this.calcularTotal();
            } else {
                this.errorBag = {};
                if (!this.ventaPendiente.Producto) {
                    this.errorBag.Producto = 'Seleccione un producto';
                }
                if (!this.ventaPendiente.Cantidad || this.ventaPendiente.Cantidad <= 0) {
                    this.errorBag.Cantidad = 'Ingrese una cantidad válida';
                }
            }
        },
        
        
        borrarProducto(index) {
            // Eliminar el producto del array ventaPendienteDetalles
            this.ventaPendienteDetalles.splice(index, 1);
            this.calcularTotal();
        },


        
        cancelCliente() {
            if (this.cliente.id) {
                this.$refs['view-ventaPendiente'].show();
            }
            this.$refs['frm-cliente'].hide();
        },
        saveCliente() {
            this.ss.storeCliente(this.cliente).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-cliente'].hide();
                    this.$refs['datatable-cliente'].reload();
                    this.getCliente();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        getDepartamento() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.departamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newCliente() {
            this.cliente = {};
            this.$refs['frm-cliente'].show();
            this.getDepartamento();
        },
        getCliente() {
            this.ss.listCliente().then(
                (result) => {
                    let response = result.data;
                    this.clientes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProducto() {
            this.ss.listProducto().then(
                (result) => {
                    let response = result.data;
                    this.productos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newVentaPendiente() {
            this.ventaPendiente = {};
            this.$refs['frm-ventaPendiente'].show();
        },
        newPago() {
            this.pago = {};
            this.$refs['frm-nuevoPago'].show();
        },
        showVenta(id) {
            // console.log(id)
            // console.log(this.response.data)
            this.isLoading=true;
            this.ss.showVenta(id).then(
                (result) => {
                    let response = result.data;
                    this.ventaPendiente = response.data;
                    this.$refs['view-ventaPendiente'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editVentaPendiente() {
            this.$refs['frm-ventaPendiente'].show();
            this.$refs['view-ventaPendiente'].hide();
        },
        cancelVentaPendiente() {
            if (this.ventaPendiente.id) {
                this.$refs['view-ventaPendiente'].show();
            }
            this.$refs['frm-ventaPendiente'].hide();
        },

        saveVentaPendiente() {
            // Estructuramos el objeto para la ventaPendiente
            console.log(this.ventaPendienteDetalles)
            let ventaPendienteData = {
                cliente_id: this.ventaPendiente.Cliente,  // Cliente seleccionado
                // estado_pago: this.ventaPendiente.MontoPagado,  // Estado del pago
                monto: this.ventaPendiente.MontoPagado,  // Estado del pago
                total: this.ventaPendiente.Total,  // Total de la ventaPendiente
                detalles: this.ventaPendienteDetalles.map(detalle => ({
                    producto_id: detalle.productId,  // ID del producto
                    cantidad: detalle.cantidad,  // Cantidad seleccionada
                    precio_unitario: detalle.precioUnitario,  // Precio del producto
                    subtotal: detalle.cantidad * detalle.precioUnitario 
                }))
            };
        
            // Enviar los datos al backend
            this.ss.storeVentaPendiente(ventaPendienteData).then(result => {
                let response = result.data;
                this.$bvToast.toast(
                    response.msg,
                    {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    }
                );
                this.$refs['frm-ventaPendiente'].hide();  // Ocultar el modal
                this.$refs['datatable-ventaPendiente'].reload();  // Recargar la tabla
                this.ventaPendienteDetalles = [];
            }).catch(error => {
                this.errorBag = error.response.data.errors;
                this.$bvToast.toast(
                    'Problema al Guardar el Registro, favor verificar los Datos',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                );
            });
        },

        // funciona bien
        deleteVentaPendiente() {
            this.$bvModal.msgBoxConfirm('Estas seguro que deseas eliminar?', {
                title: 'Confirmación',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'SI',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.ss.destroyVentaPendiente(this.ventaPendiente)
                        .then(result => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-ventaPendiente'].hide();
                            this.$refs['datatable-ventaPendiente'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
            .catch(err => {
                // Manejar el error
                console.log(err);
            });
        },
        

        draw() {
            window.$('body').on('click', '.btn-datatable-Venta', (evt) => {                
                const data = window.$(evt.target)[0].id;
                this.showVenta(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.getCliente();
            this.getProducto();
        }
    }
};
