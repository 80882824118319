import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import { jsPDF } from "jspdf";



window.$ = window.jQuery = require("jquery");

export default {
    name: "VentaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "VentaPage",
            ss: ss,
            ajax: {
                "url": ss.indexVenta(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                // { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'Nro.', orderable: false, searchable: false },
                { data: 'Persona', name: 'Nombres', title: 'Cliente' },
                { data: 'Total', name: 'Total', title: 'Total' },
                // { data: 'FechaEntrega', name: 'FechaEntrega', title: 'FechaEntrega' },
                // { data: 'EstadoPago', name: 'EstadoPago', title: 'EstadoPago' },
                // { data: 'Foto', name: 'Foto', title: 'Foto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            ventaDetalles1: [], // Aquí cargaremos los datos de la venta
            // Configuración de campos específica para el modal de ventas
            ventaFields: [
                { key: 'FechaEntrega', label: 'Fecha' },
                { key: 'Persona', label: 'Cliente' },
                { key: 'Total', label: 'Total' },
                // { key: 'EstadoPago', label: 'Estado' },
                { key: 'action', label: 'Acciones' },
            ],
            fields: [
                { key: 'productName', label: 'Producto' },
                { key: 'precioUnitario', label: 'P/U' },
                { key: 'cantidad', label: 'Cantidad' },
                { key: 'subTotal', label: 'SubTotal', formatter: (value, key, item) => (item.cantidad * item.precioUnitario).toFixed(2) },
                { key: 'eliminar', label: 'Borrar' },
            ],
            report: {
                Gestion: new Date().getFullYear().toString(),
                Mes: ''
            },
            years: Array.from(
                { length: new Date().getFullYear() - 2022 + 1 },
                (_, i) => ({ text: `${2022 + i}`, value: 2022 + i })
            ).reverse(),
            months: [
                { text: "Enero", value: "01" },
                { text: "Febrero", value: "02" },
                { text: "Marzo", value: "03" },
                { text: "Abril", value: "04" },
                { text: "Mayo", value: "05" },
                { text: "Junio", value: "06" },
                { text: "Julio", value: "07" },
                { text: "Agosto", value: "08" },
                { text: "Septiembre", value: "09" },
                { text: "Octubre", value: "10" },
                { text: "Noviembre", value: "11" },
                { text: "Diciembre", value: "12" }
            ],
            subcategorias:[],
            clientes: [],
            productos: [],
            mercadoSegunCiudads: [],
            mercadoSegunCiudadsCliente: [],
            clienteSegunMercados: [],
            categorias: {},
            listaCategorias: [],
            medidasDisponibles: [],
            medidaSeleccionada: null,
            mostrarMedidasModal: false,
            producto316: {},
            ventas: [],
            // venta: {},
            ventaDetalle: {},
            productosPorCategoria: [], // Productos filtrados por categoría seleccionada
            ventaDetalles: [], // Detalles de productos agregados a la venta
            departamentos: [],
            cliente: {},
            clienteNuevo: {},
            categoriaSeleccionada: null, // Categoría seleccionada
            productoSeleccionado: null, // Producto seleccionado para ingresar cantidad
            cantidadSeleccionada: 1, // Cantidad seleccionada
            precioSeleccionado: 1, // Cantidad seleccionada
            mostrarProductosModal: false,  // Para controlar la visibilidad del modal de productos
            mostrarCantidadModal: false,
            venta: {
                Cliente: null,
                Total: 0,
                MontoPagado: 0,
                Producto: null,
                Cantidad: 1
            },
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    methods: {
        //? Reporte total de ventas en el mes
        GenerarPDF() {
            this.ss.GenerarVentas(this.report.Gestion, this.report.Mes)
                .then(result => {
                    const response = result.data;
                    if (response.success) {
                        this.renderPDFVenta(response.data, response.statistics, this.report.Gestion); // Generar PDF con los datos
                    } else {
                        console.error("No se encontraron datos:", response.message);
                    }
                    this.$refs['frm-reporte'].hide();
                    this.report = {};
                })
                .catch(error => {
                    console.error(error);
                });
        },
        renderPDFVenta(data, statistics, gestion) {
            const doc = new jsPDF();
        
            // Título de la empresa
            doc.setFont("helvetica", "bold");
            doc.setFontSize(36);
            const companyName = "ROSABI";
            const nameWidth = doc.getTextWidth(companyName);
            const centerX = (doc.internal.pageSize.width - nameWidth) / 2;
            doc.text(companyName, centerX, 30);
        
            let yPosition = 50;
        
            // Año y Mes del reporte
            doc.setFontSize(13);
            doc.setFont("helvetica", "normal");
            doc.text(`Año: ${gestion}`, 70, yPosition);
            doc.text(`Mes: ${data[0]?.Mes || "Desconocido"}`, 115, yPosition);
        
            yPosition += 10;
            doc.text(`Cantidad de Ventas: ${statistics.Ventas}`, 38, yPosition);
        
            yPosition += 10;
            doc.text(`Ventas Pagadas: ${statistics.Pagados}`, 45, yPosition);
            doc.text(`Monto Total Pagados: ${statistics.TotalPagado.toFixed(2)}`, 115, yPosition);
        
            yPosition += 10;
            doc.text(`Ventas Pendientes: ${statistics.Pendientes}`, 40, yPosition);
            doc.text(`Monto Total en Deuda: ${statistics.TotalPendiente.toFixed(2)}`, 115, yPosition);
        
            yPosition += 20;
        
            // Encabezados de la tabla
            doc.setFont("helvetica", "bold");
            doc.setFontSize(13);
            const headers = ["Cliente", "Productos", "Total", "Pendiente"];
            const headerX = [15, 60, 140, 175];
        
            headers.forEach((header, index) => {
                doc.text(header, headerX[index], yPosition);
            });
        
            // Línea divisoria debajo de los encabezados
            doc.line(14, yPosition + 2, 200, yPosition + 2);
            yPosition += 10;
        
            // Dibujar datos
            doc.setFont("helvetica", "normal");
            const rowHeight = 5;
        
            data.forEach((venta) => {
                const productos = venta.Productos;
                const productosArray = doc.splitTextToSize(productos, 60);
        
                doc.text(venta.Cliente, 15, yPosition);
        
                let productosYPosition = yPosition;
                productosArray.forEach((line) => {
                    doc.text(line, 60, productosYPosition);
                    productosYPosition += rowHeight;
                });
        
                doc.text(venta.MontoTotal.toFixed(2), 140, yPosition);
                doc.text(venta.MontoPendiente.toFixed(2), 175, yPosition);
        
                // Dibujar línea divisoria después de cada registro
                doc.line(14, productosYPosition + 1, 200, productosYPosition + 1);
        
                yPosition = productosYPosition + 10;
        
                if (yPosition > 270) {
                    doc.addPage();
                    yPosition = 20;
                }
            });
        
            // Guardar el archivo
            doc.save(`ReporteVentas_${gestion}.pdf`);
        },
        
        
        //? Reporte deudas en el mes
        GenerarPDFdeuda() {
            this.ss.GenerarDeuda(this.report.Gestion, this.report.Mes)
                .then(result => {
                    let response = result.data;
                    if (response.success) {
                        this.renderPDF(response.data, this.report.Gestion); // Generar PDF con los datos
                    } else {
                        console.error("No se encontraron datos:", response.message);
                    }
                    this.$refs['frm-reporte-deuda'].hide();
                    this.report = {};
                })
                .catch(error => {
                    console.error("Error al generar el reporte:", error);
                });
        },
        // Ejemplo de uso para evitar el error

        renderPDF(data, gestion) {
            // Crear el PDF usando jsPDF
            const doc = new jsPDF();
            
            // Título de la empresa ROSABI, con una fuente diferente y tamaño mayor
            doc.setFont("helvetica", "bold"); // Usamos helvetica, pero puedes usar otro tipo de letra
            doc.setFontSize(36);  // Cambiar tamaño de la fuente a 36 para que sea más grande
            const companyName = "ROSABI";
            const nameWidth = doc.getTextWidth(companyName);
            const centerX = (doc.internal.pageSize.width - nameWidth) / 2;
            doc.text(companyName, centerX, 30);  // Colocamos el título más arriba
        
            // Espaciado adicional después del título
            let yPosition = 50;  // Aumentar para dar espacio debajo del título
            
            // Año seleccionado, centrado
            doc.setFontSize(14);
            doc.setFont("helvetica", "normal");
            doc.text(`Año: ${gestion}`, 94, yPosition);
            
            // Mes seleccionado, centrado
            yPosition += 10;  // Agregar un pequeño espacio entre Año y Mes
            doc.text(`Mes: ${data[0].Mes}`, 88, yPosition);
            
            // Deudas, la cantidad de registros
            yPosition += 10;  // Espacio entre Mes y Deudas
            const deudasCount = data.length; // Contar la cantidad de deudas
            doc.text(`Deudas: ${deudasCount}`, 92, yPosition);
            
            // Espaciado entre los datos y la tabla
            yPosition += 20; // Dejar más espacio antes de la tabla
            
            // Establecer encabezados de la tabla
            doc.setFont("helvetica", "bold");
            doc.setFontSize(13);
            const headers = ["Cliente", "Productos", "Total", "Pendiente"];
            const headerX = [15, 60, 140, 175]; // Posiciones de los encabezados
            
            // Dibujar encabezados de la tabla
            headers.forEach((header, index) => {
                doc.text(header, headerX[index], yPosition);
            });
        
            doc.setFont("helvetica", "normal");
            // Dibujar una línea horizontal debajo del encabezado
            doc.line(14, yPosition + 2, 200, yPosition + 2);
        
            yPosition += 10; // Ajustar la posición de la siguiente fila
        
            // Iterar sobre los datos y agregar filas a la tabla
            data.forEach((deuda) => {
                const productos = deuda.Productos;
                const rowHeight = 5;
        
                // Imprimir datos de cada fila
                doc.text(deuda.Cliente, 15, yPosition);
                
                // Para los productos, dividimos el texto si es necesario
                let productosYPosition = yPosition;
                const productosArray = doc.splitTextToSize(productos, 60); // Ajustar productos a 60 unidades de ancho
                productosArray.forEach((line) => {
                    doc.text(line, 60, productosYPosition);
                    productosYPosition += rowHeight; // Incrementar la altura por cada línea de productos
                });
                
                // Imprimir MontoTotal y MontoPendiente
                doc.text(deuda.MontoTotal.toFixed(2), 140, yPosition);
                doc.text(deuda.MontoPendiente.toFixed(2), 175, yPosition);
        
                // Dibujar una línea continua después de cada registro
                doc.line(14, productosYPosition + 1, 200, productosYPosition + 1);
        
                // Ajustar la posición para la siguiente fila
                yPosition = productosYPosition + 10;
        
                // Añadir una nueva página si llegamos al final de la página
                if (yPosition > 270) {
                    doc.addPage();
                    yPosition = 20; // Resetear la posición para la nueva página
                }
            });
            
            // Descargar el PDF
            doc.save("ReporteDeudas.pdf");
        },
        
        
        




        
        //? Para conexion de bluetooth
        async previsualizarYConectar(venta) {
            try {
                const result = await this.ss.GenerarEImprimir(venta.id);
                const response = result.data;
                
                console.log("Datos de la venta recibidos:", response);
        
                if (response && Array.isArray(response.data) && response.data.length > 0) {
                    const ventaData = response.data[0];
                    // console.log(ventaData.Cliente);
        
                    
                    // Generar el comando de impresión
                    const comandos = `
${String.fromCharCode(27)}${String.fromCharCode(69)}${String.fromCharCode(1)}
${String.fromCharCode(27)}${String.fromCharCode(33, 0x11)}
                    ROSABI
           Calle Caracoles Nro. 3685
    Urbanizacion Luis Espinal, Nueva Ventilla
                 Cel. 73725347

                BOLETA DE VENTA

Fecha:      ${ventaData.Fecha}
Cliente:    ${ventaData.Cliente}
Telefono:   ${ventaData.Telefono}
Direccion:  ${ventaData.Direccion}
Moneda:     Boliviano

Cant.    Producto             P/U      Subtotal
------------------------------------------------
${response.data.map(detalle => {
    return `${detalle.Cant.toString().padStart(4)}  ${detalle.Producto.padEnd(22)} ${parseFloat(detalle.Precio).toFixed(2).padStart(7)} ${parseFloat(detalle.Subtotal).toFixed(2).padStart(10)}`;
}).join('\n')}
------------------------------------------------

Estado:          ${ventaData.Estado}
Monto Pagado:    Bs.    ${ventaData.Pago.toFixed(2)}
Saldo Pendiente: Bs.    ${ventaData.MontoDebe.toFixed(2)}
TOTAL:           Bs.    ${ventaData.Total.toFixed(2)}


-------------------CORTE AQUI-------------------


                      VENTA
Fecha: ${ventaData.Fecha}

Cant.    Producto             P/U      Subtotal
------------------------------------------------
${response.data.map(detalle => {
    return `${detalle.Cant.toString().padStart(4)} ${detalle.Producto.padStart(6).padEnd(22)} ${parseFloat(detalle.Precio).toFixed(2).padStart(7)} ${parseFloat(detalle.Subtotal).toFixed(2).padStart(10)}`;
}).join('\n')}
------------------------------------------------

Estado:          ${ventaData.Estado}
Monto Pagado:    Bs.    ${ventaData.Pago.toFixed(2)}
Saldo Pendiente: Bs.    ${ventaData.MontoDebe.toFixed(2)}
TOTAL:           Bs.    ${ventaData.Total.toFixed(2)}


            ------------------------
                  ${ventaData.Cliente}


`;
        
                    console.log('Comando de impresión generado:', comandos);
        
                    // Codificar el comando de impresión en bytes
                    const datosCodificados = new TextEncoder().encode(comandos);
                    const MAX_BYTE_SIZE = 200;  // Tamaño máximo por cada envío de datos 512
                    console.log(MAX_BYTE_SIZE);
                    
        
                    // Suponiendo que ya tenemos la conexión establecida con la impresora
                    const dispositivo = await navigator.bluetooth.requestDevice({
                        acceptAllDevices: true,
                        optionalServices: ['49535343-fe7d-4ae5-8fa9-9fafd205e455'],
                    });
        
                    console.log(`Impresora seleccionada: ${dispositivo.name}`);
        
                    // Conectar al dispositivo
                    const servidor = await dispositivo.gatt.connect();
                    const servicio = await servidor.getPrimaryService('49535343-fe7d-4ae5-8fa9-9fafd205e455');
                    const caracteristica = await servicio.getCharacteristic('49535343-6daa-4d02-abf6-19569aca69fe');
                    
                    console.log('Conexión establecida con la impresora.');
        
                    // Enviar los datos en fragmentos más pequeños
                    for (let i = 0; i < datosCodificados.length; i += MAX_BYTE_SIZE) {
                        const fragmento = datosCodificados.slice(i, i + MAX_BYTE_SIZE);
                        await this.enviarFragmentoImpresion(fragmento, caracteristica);
                        console.log('Fragmento enviado:', fragmento);
                    }
        
                    console.log('Todos los fragmentos enviados.');
        
                } else {
                    console.log("No se encontraron datos de venta o la respuesta no tiene el formato esperado.");
                }
            } catch (error) {
                console.log(error);
            }
        },
        
        // Función para enviar cada fragmento
        async enviarFragmentoImpresion(fragmento, caracteristica) {
            try {
                // Enviar el fragmento a la impresora usando la característica
                await caracteristica.writeValue(fragmento);
                console.log('Fragmento de datos enviado a la impresora.');
            } catch (error) {
                console.error('Error al enviar el fragmento de impresión:', error);
            }
        },
        
        
        
        
        
    
  
        async imprimir() {
            try {
                // Solicitar conexión al dispositivo Bluetooth
                const dispositivo = await navigator.bluetooth.requestDevice({
                    acceptAllDevices: true, // Permite seleccionar cualquier dispositivo
                    optionalServices: ['49535343-fe7d-4ae5-8fa9-9fafd205e455'] // Reemplazar con el UUID correcto del servicio de impresión
                });

                console.log('Dispositivo conectado: ', dispositivo.name); // Mostrar el nombre del dispositivo conectado

                // Conectarse al dispositivo
                const servidor = await dispositivo.gatt.connect();

                // Acceder al servicio de impresión
                const servicio = await servidor.getPrimaryService('49535343-fe7d-4ae5-8fa9-9fafd205e455'); // Reemplazar con el UUID del servicio de impresión

                // Obtener la característica (dependiendo de cómo la impresora esté configurada)
                const caracteristica = await servicio.getCharacteristic('49535343-6daa-4d02-abf6-19569aca69fe'); // Aquí también tendrás que buscar el UUID de la característica correcta

                // Enviar el comando de impresión (en formato adecuado para la impresora)
                const texto = new TextEncoder().encode('Hola Mundo\n'); // Aquí se envía el texto de prueba

                await caracteristica.writeValue(texto); // Enviar el comando de impresión

                console.log('Conexión exitosa, datos enviados: ', texto);

                // Mostrar mensaje de éxito
                this.$bvToast.toast('¡Conexión Bluetooth exitosa y datos enviados!', {
                    title: 'Éxito',
                    variant: 'success',
                    solid: true
                });
            } catch (error) {
                console.error("Error al imprimir:", error);
                // Mostrar mensaje de error si la conexión o impresión falla
                this.$bvToast.toast('Error al conectar con la impresora. Verifique la conexión Bluetooth.', {
                    title: 'Error de Conexión',
                    variant: 'danger',
                    solid: true
                });
            }
        },
       
        
        
        // limpiar la tabla de productos
        limpiarCamposVenta() {
            this.cantidadSeleccionada = '';
            this.precioSeleccionado = '';
            this.venta.Producto = '';
            this.venta.Cantidad = '';
            this.errorBag = {};
            this.ventaDetalles = [];
            this.venta.Total = 0;
        },
        //! Nuevo Cliente
        nuevoCliente() {
            this.clienteNuevo = {};
            this.$refs['frm-nuevoCliente'].show();
        },
        cancelCliente() {
            if (this.clienteNuevo.id) {
                this.$refs['frm-venta1'].show();
            }
            this.$refs['frm-nuevoCliente'].hide();
        },
        //! Buscar Cliente
        buscarCliente() {
            this.clienteBuscar = {};
            this.$refs['frm-buscarCliente'].show();
        },
        cancelClienteB() {
            if (this.clienteBuscar.id) {
                this.$refs['frm-venta1'].show();
            }
            this.$refs['frm-buscarCliente'].hide();
        },
        //! Directo a Categoria
        mdcategoria() {
            this.limpiarCamposVenta();
            if (!this.venta.Cliente) {
                this.venta.Cliente = 1;  // ID del cliente anónimo
            }
            console.log("Categoria id Cliente: ",this.venta.Cliente);
            this.clienteNuevo.id = this.venta.Cliente;
            this.categoriaNuevo = {};
            this.$refs['frm-categoria'].show();
        },
        cancelCategoria() {
            if (this.cliente.id) {
                this.$refs['frm-venta1'].show();
            }
            this.$refs['frm-categoria'].hide();
        },
        //! Lista de ventas
        listVenta(){
            // this.report = {};
            this.cargarVentas();
            this.$refs['frm-listVenta'].show();
        },
        cargarVentas() {
            this.ss.indexVenta()
              .then(response => {
                this.ventaDetalles1 = response.data; // Asigna los datos de ventas
              })
              .catch(error => {
                console.error("Error al cargar ventas:", error);
              });
        },
        //! Reportes
        reportVenta() {
            this.report = {};
            this.$refs['frm-reporte'].show();
        },
        reportDeudores() {
            this.report = {};
            this.$refs['frm-reporte-deuda'].show();
        },
        // GenerarPDF() {
        //     // console.log(this.report.Gestion),
        //     // console.log(this.report.Mes),
        //     this.ss.GenerarVentas(this.report.Gestion, this.report.Mes)
        //         .then(
        //             result => {
        //                 let response = result.data;
        //                 // var urlFile = response.data;
        //                 // var dir =this.rutaApp + urlFile.url;
        //                 window.open(response.url);   
        //                 // console.log(response);
        //                 this.$refs['frm-reporte'].hide();
        //                 // this.$refs['datatable-venta'].reload();
        //                 this.report = {};
        //             })
        //         .catch(error => {
        //             console.log(error);
        //     });
        // },
        // GenerarPDFdeuda() {
        //     // console.log(this.report.Gestion),
        //     // console.log(this.report.Mes),
        //     this.ss.GenerarDeuda(this.report.Gestion, this.report.Mes)
        //         .then(
        //             result => {
        //                 let response = result.data;
        //                 // var urlFile = response.data;
        //                 // var dir =this.rutaApp + urlFile.url;
        //                 window.open(response.url);   
        //                 // console.log(response);
        //                 this.$refs['frm-reporte-deuda'].hide();
        //                 // this.$refs['datatable-venta'].reload();
        //                 this.report = {};
        //             })
        //         .catch(error => {
        //             console.log(error);
        //     });
        // },




        getMercadoSegunCiudad() {
            this.ss.listMercadoSegunCiudad(this.venta.Departamento).then(
                (result) => {
                    let response = result.data;
                    this.mercadoSegunCiudads = response.data;

                    console.log(this.mercadoSegunCiudads);
                    
                    this.clienteSegunMercados= [];
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getMercadoSegunCiudadCliente() {
            this.ss.listMercadoSegunCiudad(this.clienteNuevo.Departamento).then(
                (result) => {
                    let response = result.data;
                    this.mercadoSegunCiudadsCliente = response.data;

                    console.log(this.mercadoSegunCiudadsCliente);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getClienteSegunMercado() {
            // console.log(this.venta.Mercado)
            this.ss.listClienteSegunMercado(this.venta.Mercado).then(
                (result) => {
                    let response = result.data;
                    this.clienteSegunMercados = response.data;

                    console.log(this.clienteSegunMercados);
                }
            ).catch(error => {
                console.log(error);
            });
        },
        calcularTotal() {
            let total = 0;
            this.ventaDetalles.forEach(item => {
                total += item.precioUnitario * item.cantidad;
            });
            this.venta.Total = total;
        },
        // calcularTotalDescuento() {
        //     let total = 0;
        //     this.ventaDetalles.forEach(item => {
        //         total += item.precioUnitario * item.cantidad;
        //     });
        //     // this.venta.Total = total;
        //     this.venta.Total = total - this.venta.Descuento;
        // },
        saveVentaDetalle() {
            
            // console.log('Cant ', this.cantidadSeleccionada)
            // console.log('Prod. ', this.productoSeleccionado.id)

            this.venta.Producto = this.productoSeleccionado.id;
            this.venta.Cantidad = this.cantidadSeleccionada;
            this.ventaDetalles.precioUnitario = this.precioSeleccionado;

            console.log('Datos venta ',this.venta);
            console.log('Datos ventaDetalles ',this.ventaDetalles);

            this.mostrarCantidadModal = false;
            // this.cantidadSeleccionada = 0;

            if (this.venta.Producto && this.venta.Cantidad > 0) {
                const existingProduct = this.ventaDetalles.find(
                    (item) => item.productId === this.venta.Producto
                );
        
                const product = this.productos.find(
                    (p) => p.id === this.venta.Producto
                );
        
                if (existingProduct) {
                    // Si el producto ya existe, sumar la cantidad
                    existingProduct.cantidad += Number(this.venta.Cantidad);
                } else {
                    // Si el producto no existe, agregarlo al array ventaDetalles
                    this.ventaDetalles.push({
                        productId: this.venta.Producto,
                        productName: product.Producto,
                        cantidad: Number(this.venta.Cantidad),
                        precioUnitario: this.ventaDetalles.precioUnitario // Guardar el precio unitario del producto
                    });
                }
                
                this.cantidadSeleccionada = '';
                this.precioSeleccionado = '';
                this.venta.Producto = '';
                this.venta.Cantidad = '';
                this.errorBag = {};
        
                // Calcular el total de la venta
                this.calcularTotal();
            } else {
                this.errorBag = {};
                if (!this.venta.Producto) {
                    this.errorBag.Producto = 'Seleccione un producto';
                }
                if (!this.venta.Cantidad || this.venta.Cantidad <= 0) {
                    this.errorBag.Cantidad = 'Ingrese una cantidad válida';
                }
            }
        },
        // mostrarDetalles(categoria) {
        //     // Filtrar productos de la categoría seleccionada
        //     this.categoriaSeleccionada = categoria;
        //     this.productosPorCategoria = this.productos.filter(producto => producto.Categoria === categoria.id);
        //     this.mostrarProductosModal = true;
        // },
        getSubCategoria() {
            this.ss.listSubCategoria().then(
                (result) => {
                    let response = result.data;
                    this.subcategorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        mostrarDetalles(categoria) {
            this.categoriaSeleccionada = categoria;
        
            // Filtrar productos por categoría seleccionada
            const productosCategoria = this.productos.filter(producto => producto.Categoria === categoria.id);
        
            // Obtener nombres de medidas únicas de los productos de esta categoría
            this.medidasDisponibles = [
                ...new Set(
                    productosCategoria.map(producto => {
                        const subcategoria = this.subcategorias.find(sub => sub.id === producto.SubCategoria);
                        return subcategoria ? subcategoria.SubCategoria : 'Desconocido';
                    })
                )
            ];
        
            // Mostrar modal de selección de medida si hay varias disponibles
            if (this.medidasDisponibles.length > 0) {
                this.mostrarMedidasModal = true;
            } else {
                // Si no hay medidas, proceder a mostrar los productos directamente
                this.productosPorCategoria = productosCategoria;
                this.mostrarProductosModal = true;
            }
        },

        seleccionarMedida(nombreMedida) {
            this.medidaSeleccionada = nombreMedida;

            // Filtrar productos por nombre de medida seleccionada
            this.productosPorCategoria = this.productos.filter(producto => {
                const subcategoria = this.subcategorias.find(sub => sub.id === producto.SubCategoria);
                return producto.Categoria === this.categoriaSeleccionada.id && subcategoria && subcategoria.SubCategoria === nombreMedida;
            });

            // Ocultar el modal de medidas y mostrar el de productos
            this.mostrarMedidasModal = false;
            this.mostrarProductosModal = true;
        },

        
        cancelarSeleccionMedida() {
            this.mostrarMedidasModal = false;
        },
        cerrarProductosModal() {
            this.mostrarProductosModal = false;
            this.categoriaSeleccionada = null;
        },


        seleccionarProducto(producto) {
            this.cantidadSeleccionada = '';
            this.precioSeleccionado = '';
            this.venta.Producto = '';
            this.venta.Cantidad = '';
            this.productoSeleccionado = producto;
            // console.log('id Producto ',this.productoSeleccionado.id)
            this.ss.showProducto(this.productoSeleccionado.id).then(
                (result) => {
                    let response = result.data;
                    this.producto316 = response.data;
                    this.mostrarCantidadModal = true;

                    console.log('Dato Producto ',this.producto316)
                }
            ).catch(error => {
                console.log(error);
            });
        },
        cancelarSeleccionP() {
            this.mostrarProductosModal = false;
        },
        cancelarSeleccion() {
            this.productoSeleccionado = null;
            this.mostrarCantidadModal = false;
            this.mostrarProductosModal = true;
        },


        borrarProducto(index) {
            this.ventaDetalles.splice(index, 1);
            this.calcularTotal();
        },
        
        saveCliente() {
            this.ss.storeCliente(this.clienteNuevo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    );
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log('Cliente ', response);
                    console.log('ClienteID ', response.data.id);

                    this.$refs['frm-nuevoCliente'].hide();
                    // this.$refs['datatable-cliente'].reload();
                    // this.clienteNuevo = {};
                    this.mdcategoria();
                    this.clienteNuevo.id = response.data.id;
                    // this.$nextTick(() => {
                    //     console.log("Intentando abrir el modal de categoría con $nextTick");
                    //     this.$refs['frm-categoria'].show();
                    // });
                })
                .catch((error) => {
                    console.log("Error en storeCliente:", error);
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        getDepartamento() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.departamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newCliente() {
            this.cliente = {};
            this.$refs['frm-cliente'].show();
            this.getDepartamento();
        },
        modalMonto() {
            this.$refs['frm-monto'].show();
        },
        cancelmodalMonto() {
            this.$refs['frm-monto'].hide();
        },
        getCliente() {
            this.ss.listCliente().then(
                (result) => {
                    let response = result.data;
                    this.clientes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProducto() {
            this.ss.listProducto().then(
                (result) => {
                    let response = result.data;
                    this.productos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoria() {
            this.ss.listCategoria().then(
                (result) => {
                    let response = result.data;
                    this.categorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        //* modal 1
        newVenta() {
            this.venta = {};
            this.$refs['frm-venta1'].show();
            this.getCliente();
            this.getCategoria();
        },
        showVenta(id) {
            this.isLoading=true;
            this.ss.showVenta(id).then(
                (result) => {
                    let response = result.data;
                    this.venta = response.data;
                    this.$refs['view-venta'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editVenta() {
            this.$refs['frm-venta'].show();
            this.$refs['view-venta'].hide();
        },
        cancelVenta() {
            if (this.venta.id) {
                this.$refs['view-venta'].show();
            }
            this.$refs['frm-venta'].hide();
        },
        GenerarVenta(venta) {
            this.ss.GenerarVentaID(venta.id)
                .then(
                    result => {
                        let response = result.data;
                        window.open(response.url);   
                        console.log(response)
                    })
                .catch(error => {
                    console.log(error);
            });
        },
        saveVenta() {
            // console.log(this.ventaDetalles)
            console.log("Venta cliente id: ", this.clienteNuevo.id);
            console.log("default cliente id: ", this.venta.Cliente);
            
            let ventaData = {
                // cliente_id: this.venta.Cliente,  
                cliente_id: this.clienteNuevo.id,
                monto: Number(this.venta.MontoPagado) || 0,
                total: this.venta.Total,
                detalles: this.ventaDetalles.map(detalle => ({
                    producto_id: detalle.productId,  
                    cantidad: detalle.cantidad,  
                    precio_unitario: detalle.precioUnitario,  
                    subtotal: detalle.cantidad * detalle.precioUnitario 
                }))
            };
            // Enviar los datos al backend
            this.ss.storeVenta(ventaData).then(result => {
                let response = result.data;
                console.log(response.data.id);
                // Genera PDF
                // this.ss.GenerarVentaID(response.data.id)
                //     .then(
                //         result => {
                //             let response = result.data;
                //             window.open(response.url);   
                //             // console.log(response)
                //         })
                //     .catch(error => {
                //         console.log(error);
                // });
                this.previsualizarYConectar({ id: response.data.id });
                this.$bvToast.toast(
                    response.msg,
                    {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    }
                );

                this.$refs['frm-monto'].hide();
                this.$refs['frm-categoria'].hide();
                this.$refs['frm-venta1'].hide();
                this.$refs['frm-buscarCliente'].hide();
                // this.$refs['frm-venta'].hide();
                this.$refs['datatable-venta'].reload();
                this.ventaDetalles = [];
            }).catch(error => {
                this.errorBag = error.response.data.errors;
                this.$bvToast.toast(
                    'Problema al Guardar el Registro, favor verificar los Datos',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                );
            });
        },
        
        // deleteVenta() {
        //     this.$swal({
        //         title: "Estas seguro que deseas eliminar?",
        //         text: "Esta accion es irreversible!",
        //         icon: "warning",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        //         .then((willDelete) => {
        //             if (willDelete) {
        //                 this.ss.destroyVenta(this.venta)
        //                     .then((result) => {
        //                         let response = result.data;
        //                         console.log(response);
        //                         this.$bvToast.toast(
        //                             response.msg,
        //                             {
        //                                 title: 'Correcto',
        //                                 variant: 'success',
        //                                 autoHideDelay: 5000
        //                             }
        //                         )
        //                         this.$refs['view-venta'].hide();
        //                         this.$refs['datatable-venta'].reload();
        //                     })
        //                     .catch(error => {
        //                         console.log(error);
        //                     })
        //             } else {
        //                 //swal("Your imaginary file is safe!");
        //             }
        //         });
        // },
        // funciona bien
        deleteVenta() {
            this.$bvModal.msgBoxConfirm('Estas seguro que deseas eliminar?', {
                title: 'Confirmación',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'SI',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.ss.destroyVenta(this.venta)
                        .then(result => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-venta'].hide();
                            this.$refs['datatable-venta'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
            .catch(err => {
                // Manejar el error
                console.log(err);
            });
        },

        // tiene un mejor diseño
        // deleteVenta() {
        //     this.$swal({
        //         title: "Estas seguro que deseas eliminar?",
        //         text: "Esta acción es irreversible!",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonText: "YES",
        //         cancelButtonText: "NO",
        //         customClass: {
        //             confirmButton: 'btn btn-danger',
        //             cancelButton: 'btn btn-secondary'
        //         },
        //         buttonsStyling: false
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.ss.destroyVenta(this.venta)
        //                 .then((result) => {
        //                     let response = result.data;
        //                     console.log(response);
        //                     this.$bvToast.toast(
        //                         response.msg,
        //                         {
        //                             title: 'Correcto',
        //                             variant: 'success',
        //                             autoHideDelay: 5000
        //                         }
        //                     );
        //                     this.$refs['view-venta'].hide();
        //                     this.$refs['datatable-venta'].reload();
        //                 })
        //                 .catch(error => {
        //                     console.log(error);
        //                 });
        //         }
        //     });
        // },
        
        
        draw() {
            window.$('body').on('click', '.btn-datatable-Venta', (evt) => {                
                const data = window.$(evt.target)[0].id;
                this.showVenta(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            // this.getCliente();
            this.getSubCategoria();
            this.cargarVentas();
            this.getDepartamento();
            this.getProducto();
            this.getCategoria();
        }
    }
};
