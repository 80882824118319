import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CategoriaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CategoriaPage",
            ss: ss,
            ajax: {
                "url": ss.indexCategoria(),
                headers: ss.getToken(),
            },
            columns: [
                { 
                    data: 'URLFoto', 
                    name: 'Foto', 
                    title: 'Foto',
                    render: function(data, type, row) {
                        if (data) {
                            return `<img src="${data}" alt="${row.Categoria}" width="50" height="50"/>`;
                        }
                        return 'Sin imagen';
                    }
                },
                { data: 'Categoria', name: 'Categoria', title: 'Categoria' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            categorias: [],
            categoria: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.categoria.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        newCategoria() {
            this.categoria = {};
            this.$refs['frm-categoria'].show();
        },
        showCategoria(id) {
            this.isLoading=true;
            this.ss.showCategoria(id).then(
                (result) => {
                    let response = result.data;
                    this.categoria = response.data;
                    this.$refs['view-categoria'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editCategoria() {
            this.$refs['frm-categoria'].show();
            this.$refs['view-categoria'].hide();
        },
        cancelCategoria() {
            if (this.categoria.id) {
                this.$refs['view-categoria'].show();
            }
            this.$refs['frm-categoria'].hide();
        },
        saveCategoria() {
            this.ss.storeCategoria(this.categoria).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-categoria'].hide();
                    this.$refs['datatable-categoria'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCategoria() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyCategoria(this.categoria)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-categoria'].hide();
                                this.$refs['datatable-categoria'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('body').on('click', '.btn-datatable-Categoria', (evt) => {
                console.log('Entro', evt);
                console.log('Target:', evt.target);
                console.log('ID:', evt.target.id);
                
                const data = window.$(evt.target)[0].id;
                this.showCategoria(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }
    }
};
