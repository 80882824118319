import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import ProductoPage from '@/pages/Producto/ProductoPage.vue'
import ProduccionPage from '@/pages/Produccion/ProduccionPage.vue'
import DepartamentoPage from '@/pages/Departamento/DepartamentoPage.vue'
import MercadoPage from '@/pages/Mercado/MercadoPage.vue'
import ClientePage from '@/pages/Cliente/ClientePage.vue'
import VentaPage from '@/pages/Venta/VentaPage.vue'
import VentaPagadaPage from '@/pages/VentaPagada/VentaPagadaPage.vue'
import VentaPendientePage from '@/pages/VentaPendiente/VentaPendientePage.vue'
import CategoriaPage from '@/pages/Categoria/CategoriaPage.vue'
import SubCategoriaPage from '@/pages/SubCategoria/SubCategoriaPage.vue'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Producto',
      name: 'Producto',
      component: ProductoPage
    },
    {
      path: '/Produccion',
      name: 'Produccion',
      component: ProduccionPage
    },
    {
      path: '/Categoria',
      name: 'Categoria',
      component: CategoriaPage
    },
    {
      path: '/SubCategoria',
      name: 'SubCategoria',
      component: SubCategoriaPage
    },
    {
      path: '/Departamento',
      name: 'Departamento',
      component: DepartamentoPage
    },
    {
      path: '/Mercado',
      name: 'Mercado',
      component: MercadoPage
    },
    {
      path: '/Cliente',
      name: 'Cliente',
      component: ClientePage
    },
    {
      path: '/Venta',
      name: 'Venta',
      component: VentaPage
    },
    {
      path: '/VentaPagada',
      name: 'VentaPagada',
      component: VentaPagadaPage
    },
    {
      path: '/VentaPendiente',
      name: 'VentaPendiente',
      component: VentaPendientePage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    }
  ]
})