import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ProduccionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ProduccionPage",
            ss: ss,
            ajax: {
                "url": ss.indexProduccion(),
                headers: ss.getToken(),
            },
            columns: [
                // { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                // { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'Nro', orderable: false, searchable: false },
                { data: 'Chapeada', name: 'Chapeada', title: 'Chapeada' },
                { data: 'Panetones', name: 'Panetones', title: 'Panetones' },
                { data: 'Medida', name: 'Medida', title: 'Medida' },
                { data: 'Tipo', name: 'Tipo', title: 'Tipo' },
                { data: 'Fallidos', name: 'Medida', title: 'Fallidos' },
                { data: 'Total', name: 'Medida', title: 'Total' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            // roduccion.Fallidos = 0;
            produccions: [],
            // produccion: { Fallidos:0 },
            produccion: { Panetones: 0, Fallidos: 0, Total: 0 },
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    watch: {
        'produccion.Panetones': 'validateFallidos',
        'produccion.Fallidos': 'validateFallidos',
    },
    methods: {
        validateFallidos() {
            // Establecemos un debounce para esperar a que se termine de escribir antes de validar
            this.$nextTick(() => {
                // Validar que Fallidos no sea mayor que Panetones
                if (Number(this.produccion.Fallidos) > Number(this.produccion.Panetones)) {
                    this.errorBag.Fallidos = 'Los fallidos no pueden ser mayores que la cantidad de panetones.';
                    this.produccion.Fallidos = this.produccion.Panetones;
                } else {
                    this.errorBag.Fallidos = '';
                }
                // Calculamos el total
                this.calculateTotal();
            });
        },
        calculateTotal() {
            this.produccion.Total = this.produccion.Panetones - this.produccion.Fallidos;
        },
        newProduccion() {
            this.produccion = {};
            this.$refs['frm-produccion'].show();
        },
        showProduccion(id) {
            this.isLoading=true;
            this.ss.showProduccion(id).then(
                (result) => {
                    let response = result.data;
                    this.produccion = response.data;
                    this.$refs['view-produccion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editProduccion() {
            this.$refs['frm-produccion'].show();
            this.$refs['view-produccion'].hide();
        },
        cancelProduccion() {
            if (this.produccion.id) {
                this.$refs['view-produccion'].show();
            }
            this.$refs['frm-produccion'].hide();
        },
        saveProduccion() {
            this.ss.storeProduccion(this.produccion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-produccion'].hide();
                    this.$refs['datatable-produccion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteProduccion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyProduccion(this.produccion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-produccion'].hide();
                                this.$refs['datatable-produccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        draw() {
            window.$('body').on('click', '.btn-datatable-Produccion', (evt) => {                
                const data = window.$(evt.target)[0].id;
                this.showProduccion(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }
    }
};
