import { render, staticRenderFns } from "./SubCategoriaPage.vue?vue&type=template&id=e9d5a12a&scoped=true"
import script from "./SubCategoriaPage.js?vue&type=script&lang=js&external"
export * from "./SubCategoriaPage.js?vue&type=script&lang=js&external"
import style0 from "./SubCategoriaPage.vue?vue&type=style&index=0&id=e9d5a12a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9d5a12a",
  null
  
)

export default component.exports