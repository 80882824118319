<template>
    <div id="app">
        <v-app id="inspire">
            <v-app-bar
                app
                dark
                flat
                v-if="this.$router.currentRoute.name !== 'Login'"
                :style="{
                    backgroundImage: scrolled ? 'url(https://rosweb.rosabiweb.com/images/loading1.png)' : 'none',
                    backgroundColor: scrolled ? '#ac0f0f' : 'transparent',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    transition: 'background-image 0.3s ease-in-out, background-color 0.5s ease-in-out'
                }"
            >
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                >
                </v-app-bar-nav-icon>
                <v-spacer></v-spacer>

                <v-btn icon>
                    <v-icon @click.prevent="cerrarSesion">mdi-logout</v-icon>
                </v-btn>
            </v-app-bar>

            <v-navigation-drawer
                color="white"
                v-model="drawer"
                :permanent="$vuetify.breakpoint.mdAndUp"
                app
                flat
                v-if="this.$router.currentRoute.name !== 'Login'"
                style="
                    background: url(https://rosweb.rosabiweb.com/images/img8.png) center / cover;
                "
            >
            <template v-slot:prepend>
                    <br>
                    <v-list-item two-line>
                        <v-list-item-avatar style="height: 90px; width: 90px; margin: 0 auto; border: 2px solid white;">
                            <img :src="auth.URLFoto" />
                        </v-list-item-avatar>
                    </v-list-item>
                    
                    <v-list-item-content class="text-center text-dark">
                        <v-list-item-title>
                            {{ auth.Persona }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            >Sesion Iniciada
                        </v-list-item-subtitle>
                    </v-list-item-content>   
                </template>
                <v-divider></v-divider>
                <MenuVuetify />
            </v-navigation-drawer>

            <!-- Ajusta el fondo según el tamaño de la pantalla -->
            <v-main
                :style="this.$router.currentRoute.name === 'PrincipalPage' ? 
                    'background-image: url(https://rosweb.rosabiweb.com/images/bienvenido.jpeg); background-size: cover; background-position: center; height: 100vh; padding-left: 0px;' : ''"
                :class="{'main-background': this.$router.currentRoute.name === 'PrincipalPage'}"
                style="background: #AC0F0F;"
            >
                <router-view color="#c0392b"></router-view>
            </v-main>
        </v-app>
    </div>
</template>

<script>
import MenuVuetify from "./partials/MenuVuetify.vue";
export default {
    name: "app",
    components: {
        MenuVuetify,
    },
    data: () => ({
        scrolled: false,
        drawer: false,
        auth: {},
    }),
    mounted() {
        this.auth = JSON.parse(localStorage.getItem("persona"));
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.scrolled = window.scrollY > 0;
        },
        cerrarSesion() {
            localStorage.clear();
            this.$router.push("/Login");
            if (this.$msal.isAuthenticated()) {
                this.$msal.signOut();
            }
            this.$router.go();
        },
    },
};
</script>

<style lang="scss">
@import "./assets/css/app.css";
@import "./assets/scss/app.scss";

/* Ajustes adicionales para pantallas grandes */

@media (min-width: 960px) {
    .main-background {
        background-size: cover;
        background-position: center;
        width: calc(100% - 240px);
        margin-left: 240px;
    }
}
</style>
