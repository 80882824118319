import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DepartamentoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "DepartamentoPage",
            ss: ss,
            ajax: {
                "url": ss.indexDepartamento(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'Departamento', name: 'Departamento', title: 'Departamento' },
                // { data: 'Foto', name: 'Foto', title: 'Foto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            departamentos: [],
            departamento: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.departamento.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        newDepartamento() {
            this.departamento = {};
            window.history.pushState(null, null, window.location.href);
            this.$refs['frm-departamento'].show();
        },
        showDepartamento(id) {
            this.isLoading=true;
            this.ss.showDepartamento(id).then(
                (result) => {
                    let response = result.data;
                    this.departamento = response.data;
                    this.$refs['view-departamento'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editDepartamento() {
            this.$refs['frm-departamento'].show();
            this.$refs['view-departamento'].hide();
        },
        cancelDepartamento() {
            if (this.departamento.id) {
                this.$refs['view-departamento'].show();
            }
            this.$refs['frm-departamento'].hide();
        },
        saveDepartamento() {
            this.ss.storeDepartamento(this.departamento).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-departamento'].hide();
                    this.$refs['datatable-departamento'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        // deleteDepartamento() {
        //     this.$swal({
        //         title: "Estas seguro que deseas eliminar?",
        //         text: "Esta accion es irreversible!",
        //         icon: "warning",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        //         .then((willDelete) => {
        //             if (willDelete) {
        //                 this.ss.destroyDepartamento(this.departamento)
        //                     .then((result) => {
        //                         let response = result.data;
        //                         console.log(response);
        //                         this.$bvToast.toast(
        //                             response.msg,
        //                             {
        //                                 title: 'Correcto',
        //                                 variant: 'success',
        //                                 autoHideDelay: 5000
        //                             }
        //                         )
        //                         this.$refs['view-departamento'].hide();
        //                         this.$refs['datatable-departamento'].reload();
        //                     })
        //                     .catch(error => {
        //                         console.log(error);
        //                     })
        //             } else {
        //                 //swal("Your imaginary file is safe!");
        //             }
        //         });
        // },
        // funciona bien
        deleteDepartamento() {
            this.$bvModal.msgBoxConfirm('Estas seguro que deseas eliminar?', {
                title: 'Confirmación',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'SI',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.ss.destroyDepartamento(this.departamento)
                        .then(result => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-departamento'].hide();
                            this.$refs['datatable-departamento'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
            .catch(err => {
                // Manejar el error
                console.log(err);
            });
        },
        handleBackButton(event) {
            // Si el modal está abierto, ciérralo y evita el retroceso
            if (this.$refs["frm-departamento"].isVisible) {
                event.preventDefault();
                this.$refs['frm-departamento'].hide();
            } else {
                // Permitir el comportamiento normal del botón "Atrás"
                window.history.back();
            }
        },

        // tiene un mejor diseño
        // deleteDepartamento() {
        //     this.$swal({
        //         title: "Estas seguro que deseas eliminar?",
        //         text: "Esta acción es irreversible!",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonText: "YES",
        //         cancelButtonText: "NO",
        //         customClass: {
        //             confirmButton: 'btn btn-danger',
        //             cancelButton: 'btn btn-secondary'
        //         },
        //         buttonsStyling: false
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.ss.destroyDepartamento(this.departamento)
        //                 .then((result) => {
        //                     let response = result.data;
        //                     console.log(response);
        //                     this.$bvToast.toast(
        //                         response.msg,
        //                         {
        //                             title: 'Correcto',
        //                             variant: 'success',
        //                             autoHideDelay: 5000
        //                         }
        //                     );
        //                     this.$refs['view-departamento'].hide();
        //                     this.$refs['datatable-departamento'].reload();
        //                 })
        //                 .catch(error => {
        //                     console.log(error);
        //                 });
        //         }
        //     });
        // },
        
        draw() {
            window.$('body').on('click', '.btn-datatable-Departamento', (evt) => {                
                const data = window.$(evt.target)[0].id;
                this.showDepartamento(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        window.addEventListener("popstate", this.handleBackButton);
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }
    },
    beforeDestroy() {
        // Elimina el listener cuando el componente se destruye
        window.removeEventListener("popstate", this.handleBackButton);
    },
};
