import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "MercadoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MercadoPage",
            ss: ss,
            ajax: {
                "url": ss.indexMercado(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'Mercado', name: 'me.Mercado', title: 'Mercado' },
                { data: 'Departamento', name: 'dep.Departamento', title: 'Ciudad' },
                // { data: 'Foto', name: 'Foto', title: 'Foto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            departamentos: [],
            mercados: [],
            mercado: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    methods: {
        getDepartamento() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.departamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newMercado() {
            this.mercado = {};
            this.$refs['frm-mercado'].show();
        },
        showMercado(id) {
            this.isLoading=true;
            this.ss.showMercado(id).then(
                (result) => {
                    let response = result.data;
                    this.mercado = response.data;
                    this.$refs['view-mercado'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editMercado() {
            this.$refs['frm-mercado'].show();
            this.$refs['view-mercado'].hide();
        },
        cancelMercado() {
            if (this.mercado.id) {
                this.$refs['view-mercado'].show();
            }
            this.$refs['frm-mercado'].hide();
        },
        saveMercado() {
            this.ss.storeMercado(this.mercado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-mercado'].hide();
                    this.$refs['datatable-mercado'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        // deleteMercado() {
        //     this.$swal({
        //         title: "Estas seguro que deseas eliminar?",
        //         text: "Esta accion es irreversible!",
        //         icon: "warning",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        //         .then((willDelete) => {
        //             if (willDelete) {
        //                 this.ss.destroyMercado(this.mercado)
        //                     .then((result) => {
        //                         let response = result.data;
        //                         console.log(response);
        //                         this.$bvToast.toast(
        //                             response.msg,
        //                             {
        //                                 title: 'Correcto',
        //                                 variant: 'success',
        //                                 autoHideDelay: 5000
        //                             }
        //                         )
        //                         this.$refs['view-mercado'].hide();
        //                         this.$refs['datatable-mercado'].reload();
        //                     })
        //                     .catch(error => {
        //                         console.log(error);
        //                     })
        //             } else {
        //                 //swal("Your imaginary file is safe!");
        //             }
        //         });
        // },
        // funciona bien
        deleteMercado() {
            this.$bvModal.msgBoxConfirm('Estas seguro que deseas eliminar?', {
                title: 'Confirmación',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'SI',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.ss.destroyMercado(this.mercado)
                        .then(result => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-mercado'].hide();
                            this.$refs['datatable-mercado'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
            .catch(err => {
                // Manejar el error
                console.log(err);
            });
        },

        // tiene un mejor diseño
        // deleteMercado() {
        //     this.$swal({
        //         title: "Estas seguro que deseas eliminar?",
        //         text: "Esta acción es irreversible!",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonText: "YES",
        //         cancelButtonText: "NO",
        //         customClass: {
        //             confirmButton: 'btn btn-danger',
        //             cancelButton: 'btn btn-secondary'
        //         },
        //         buttonsStyling: false
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.ss.destroyMercado(this.mercado)
        //                 .then((result) => {
        //                     let response = result.data;
        //                     console.log(response);
        //                     this.$bvToast.toast(
        //                         response.msg,
        //                         {
        //                             title: 'Correcto',
        //                             variant: 'success',
        //                             autoHideDelay: 5000
        //                         }
        //                     );
        //                     this.$refs['view-mercado'].hide();
        //                     this.$refs['datatable-mercado'].reload();
        //                 })
        //                 .catch(error => {
        //                     console.log(error);
        //                 });
        //         }
        //     });
        // },
        

        draw() {
            window.$('body').on('click', '.btn-datatable-Mercado', (evt) => {                
                const data = window.$(evt.target)[0].id;
                this.showMercado(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.getDepartamento();
        }
    }
};
