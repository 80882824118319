import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "VentaPagadaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "VentaPagadaPage",
            ss: ss,
            ajax: {
                "url": ss.indexVentaPagada(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                // { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'Nro.', orderable: false, searchable: false },
                { data: 'Persona', name: 'Nombres', title: 'Cliente' },
                { data: 'Total', name: 'Total', title: 'Total' },
                // { data: 'FechaEntrega', name: 'FechaEntrega', title: 'FechaEntrega' },
                // { data: 'EstadoPago', name: 'EstadoPago', title: 'EstadoPago' },
                // { data: 'Foto', name: 'Foto', title: 'Foto' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            fields: [
                { key: 'productName', label: 'Producto' },
                { key: 'precioUnitario', label: 'P/U' },
                { key: 'cantidad', label: 'Cantidad' },
                { key: 'subTotal', label: 'SubTotal', formatter: (value, key, item) => (item.cantidad * item.precioUnitario).toFixed(2) },
                { key: 'eliminar', label: 'Borrar' },
            ],
            clientes: [],
            productos: [],
            ventaPagadaDetalles: [],
            ventaPagadas: [],
            ventaPagada: {},
            ventaPagadaDetalle: {},
            departamentos: [],
            cliente: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {}
        };
    },
    methods: {
        calcularTotal() {
            let total = 0;
            this.ventaPagadaDetalles.forEach(item => {
                total += item.precioUnitario * item.cantidad;
            });
            this.ventaPagada.Total = total;
        },
        saveVentaPagadaDetalle() {
            if (this.ventaPagada.Producto && this.ventaPagada.Cantidad > 0) {
                const existingProduct = this.ventaPagadaDetalles.find(
                    (item) => item.productId === this.ventaPagada.Producto
                );
        
                const product = this.productos.find(
                    (p) => p.id === this.ventaPagada.Producto
                );
        
                if (existingProduct) {
                    // Si el producto ya existe, sumar la cantidad
                    existingProduct.cantidad += Number(this.ventaPagada.Cantidad);
                } else {
                    // Si el producto no existe, agregarlo al array ventaPagadaDetalles
                    this.ventaPagadaDetalles.push({
                        productId: this.ventaPagada.Producto,
                        productName: product.Producto,
                        cantidad: Number(this.ventaPagada.Cantidad),
                        precioUnitario: product.PrecioUnitario // Guardar el precio unitario del producto
                    });
                }
        
                this.ventaPagada.Producto = '';
                this.ventaPagada.Cantidad = '';
                this.errorBag = {};
        
                // Calcular el total de la ventaPagada
                this.calcularTotal();
            } else {
                this.errorBag = {};
                if (!this.ventaPagada.Producto) {
                    this.errorBag.Producto = 'Seleccione un producto';
                }
                if (!this.ventaPagada.Cantidad || this.ventaPagada.Cantidad <= 0) {
                    this.errorBag.Cantidad = 'Ingrese una cantidad válida';
                }
            }
        },
        
        
        borrarProducto(index) {
            // Eliminar el producto del array ventaPagadaDetalles
            this.ventaPagadaDetalles.splice(index, 1);
            this.calcularTotal();
        },


        
        cancelCliente() {
            if (this.cliente.id) {
                this.$refs['view-ventaPagada'].show();
            }
            this.$refs['frm-cliente'].hide();
        },
        saveCliente() {
            this.ss.storeCliente(this.cliente).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-cliente'].hide();
                    this.$refs['datatable-cliente'].reload();
                    this.getCliente();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        getDepartamento() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.departamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newCliente() {
            this.cliente = {};
            this.$refs['frm-cliente'].show();
            this.getDepartamento();
        },
        getCliente() {
            this.ss.listCliente().then(
                (result) => {
                    let response = result.data;
                    this.clientes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProducto() {
            this.ss.listProducto().then(
                (result) => {
                    let response = result.data;
                    this.productos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newVentaPagada() {
            this.ventaPagada = {};
            this.$refs['frm-ventaPagada'].show();
        },
        showVenta(id) {
            this.isLoading=true;
            this.ss.showVenta(id).then(
                (result) => {
                    let response = result.data;
                    this.ventaPagada = response.data;
                    this.$refs['view-ventaPagada'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editVentaPagada() {
            this.$refs['frm-ventaPagada'].show();
            this.$refs['view-ventaPagada'].hide();
        },
        cancelVentaPagada() {
            if (this.ventaPagada.id) {
                this.$refs['view-ventaPagada'].show();
            }
            this.$refs['frm-ventaPagada'].hide();
        },

        saveVentaPagada() {
            // Estructuramos el objeto para la ventaPagada
            console.log(this.ventaPagadaDetalles)
            let ventaPagadaData = {
                cliente_id: this.ventaPagada.Cliente,  // Cliente seleccionado
                // estado_pago: this.ventaPagada.MontoPagado,  // Estado del pago
                monto: this.ventaPagada.MontoPagado,  // Estado del pago
                total: this.ventaPagada.Total,  // Total de la ventaPagada
                detalles: this.ventaPagadaDetalles.map(detalle => ({
                    producto_id: detalle.productId,  // ID del producto
                    cantidad: detalle.cantidad,  // Cantidad seleccionada
                    precio_unitario: detalle.precioUnitario,  // Precio del producto
                    subtotal: detalle.cantidad * detalle.precioUnitario 
                }))
            };
        
            // Enviar los datos al backend
            this.ss.storeVentaPagada(ventaPagadaData).then(result => {
                let response = result.data;
                this.$bvToast.toast(
                    response.msg,
                    {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    }
                );
                this.$refs['frm-ventaPagada'].hide();  // Ocultar el modal
                this.$refs['datatable-ventaPagada'].reload();  // Recargar la tabla
                this.ventaPagadaDetalles = [];
            }).catch(error => {
                this.errorBag = error.response.data.errors;
                this.$bvToast.toast(
                    'Problema al Guardar el Registro, favor verificar los Datos',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                );
            });
        },

        // funciona bien
        deleteVentaPagada() {
            this.$bvModal.msgBoxConfirm('Estas seguro que deseas eliminar?', {
                title: 'Confirmación',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'SI',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.ss.destroyVentaPagada(this.ventaPagada)
                        .then(result => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-ventaPagada'].hide();
                            this.$refs['datatable-ventaPagada'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
            .catch(err => {
                // Manejar el error
                console.log(err);
            });
        },
        
        draw() {
            window.$('body').on('click', '.btn-datatable-Venta', (evt) => {                
                const data = window.$(evt.target)[0].id;
                this.showVenta(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.getCliente();
            this.getProducto();
        }
    }
};
