<template>
    <v-list nav dense>
        <v-list-item-group>
            
            <router-link
                class="black-text text--accent-4"
                to="Profile"
                active-class="red--text text--accent-4"
                style="text-decoration: none; "
                v-if="this.auth.Rol==1 || this.auth.Rol==2"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fa-address-card</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Perfil
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="red--text text--accent-4"
                style="text-decoration: none;"
                v-if="this.auth.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="red--text text--accent-4"
                style="text-decoration: none;"
                v-if="this.auth.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Usuarios
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <v-list-group
                prepend-icon="mdi-account-plus"
                active-class="red--text text--accent-4"
                v-if="this.auth.Rol==1"
            >
                <template v-slot:activator >
                    <v-list-item-title>Clientes</v-list-item-title>
                </template>
                <!-- -------- Cliente  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="Cliente"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <v-icon class="ml-5">mdi-circle-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Cliente                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- -------- Mercado  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="Mercado"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <v-icon class="ml-5">mdi-target</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Mercado                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- -------- Ciudad  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="Departamento"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <v-icon class="ml-5">mdi-map-marker</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Ciudad                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-group
                prepend-icon="mdi-cart-plus"
                active-class="red--text text--accent-4"
                v-if="this.auth.Rol==1"
            >
                <template v-slot:activator >
                    <v-list-item-title>Productos</v-list-item-title>
                </template>
                <!-- -------- Articulo  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="Producto"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <v-icon class="ml-5">mdi-tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Articulo                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                
                <!-- -------- Unidad de Medida  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="SubCategoria"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <v-icon class="ml-5">mdi-tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Medida                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- -------- Categoria  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="Categoria"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <v-icon class="ml-5">mdi-tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Categoria                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-group
                prepend-icon="mdi-store"
                active-class="red--text text--accent-4"
                v-if="this.auth.Rol==1 || this.auth.Rol==2"
            >
                <template v-slot:activator >
                    <v-list-item-title>Ventas</v-list-item-title>
                </template>
                <!-- -------- Punto de Venta  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="Venta"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <v-icon class="ml-5">mdi-truck</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Punto de Venta                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- -------- Ventas Completas  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="VentaPagada"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <!-- <v-icon class="ml-5">mdi-cash-100</v-icon> -->
                        <v-icon class="fa-solid fa-sack-dollar ml-5"></v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Ventas Completas                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- -------- Lista de Deudas  ---------------- -->
                <v-list-item
                    class="text--accent-4"
                    to="VentaPendiente"
                    active-class="red--text text--accent-4"
                    style="text-decoration: none; "
                >    
                    <v-list-item-action>
                        <v-icon class="fa-solid fa-hand-holding-dollar ml-5"></v-icon>
                        <!-- <v-icon class="ml-5">mdi-currency-usd</v-icon> -->
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Lista de Deudas                              
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <router-link
                class="black--text text--accent-4"
                to="Produccion"
                active-class="red--text text--accent-4"
                style="text-decoration: none;"
                v-if="this.auth.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-poll</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Produccion
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-item-group>
    </v-list>
</template>

<script>
import MainService from "@/services/MainService.js";
export default {
    name: "MenuVuetify",
    data() {
        let ss = new MainService();
        return {
            ss: ss,
            msg: "MenuVuetify",
            auth:{
                rol:'',
                id:''
            }
        };
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!this.auth) {
            this.$router.push('/Login');
        } 
    }
};
</script>