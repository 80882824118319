<template>
    <div class="login-box-body dimension">
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="row justify-content-center align-items-center">
            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <img
                    src="@/assets/images/loading1.png"
                    alt="Rosabi"
                    class="logo"
                />
            </div>
        </div>
        <br />
        <br />
        <br />
    </div>
</template>
<!-- <template>
    <div v-if="isLoading" class="overlay">
      <div class="d-flex justify-content-center align-items-center h-100">
        <img src="@/assets/images/emi_logo.png" alt="EMI" class="logo" />
      </div>
    </div>
</template> -->
<script>
export default {
    name: "Loader",
    props: ["isLoading"],
    // props: ["isLoading"],
    data: function () {
        return {
            color: "#52B87F",
            size: "48px",
        };
    },
};
</script>
<style scopped>
.dimension {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2305px !important; /*change to YOUR page height*/
    background-color: #000;
    filter: alpha(opacity=50);
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    opacity: 0.8;
    z-index: 998;
}

.logo {
    width: 100%;
}
</style>
<!-- <style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro con opacidad */
  z-index: 999; /* Asegurarte de que esté encima de todo */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 50%;
  max-width: 200px; /* Limitar el tamaño en pantallas grandes */
  height: auto;
}
</style> -->


<!-- esto da pero no muestra -->
<!-- <template>
    <div v-if="isLoading" class="overlay">
      <img src="/assets/images/emi_logo.png" alt="EMI" class="logo" />
    </div>
  </template>
  
  <script>
  export default {
    name: "Loader",
    props: ["isLoading"],
  };
  </script>
  
  <style scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo {
    max-width: 80%;
    height: auto;
  }
  </style> -->